@font-face {
  font-family: 'MiFuentemenu';
  src: url('../../public/letras/BarlowCondensed-Medium.ttf') format('truetype');
  /* Puedes agregar más formatos aquí si lo deseas */

}

@font-face {
  font-family: 'MiFuentenoticiastitulo';
  src: url('../../public/letras/DINCond-Black.otf') format('truetype');
  /* Puedes agregar más formatos aquí si lo deseas */

}

@font-face {
  font-family: 'MiFuentetitulo';
  src: url('../../public/letras/DINCond-Medium.otf') format('truetype');
  /* Puedes agregar más formatos aquí si lo deseas */

}

@font-face {
  font-family: 'MiFuenteParrafo';
  src: url('../../public/letras/Museo500-Regular.otf') format('truetype');
  /* Puedes agregar más formatos aquí si lo deseas */

}

$primary: rgba(59, 160, 168, 1);
$danger: rgba(215, 65, 42, 1);



$theme-colors: (
  "primary": $primary,
  "danger": $danger
);

/* @import "../node_modules/bootstrap/scss/maps"; */
/* @import "../node_modules/bootstrap/scss/mixins"; */
/* @import "../node_modules/bootstrap/scss/root"; */
@import "../../node_modules/bootstrap/scss/bootstrap";

.margen {
  width: 83%;
  margin: 0 auto 0 auto; /* Ajusta el margen superior y centra horizontalmente */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.container a:hover {
  color: rgba(215, 65, 42);
  /*   filter: drop-shadow(0px 0px 2px rgba(215,65,42)); */
}
.jus{
  text-align: left;
}
h1 {
  text-align: center;
  padding: 3rem;
}

.drop {
  filter: drop-shadow(0px 0px 6px rgb(255, 255, 255));
}

.luz2 {
  box-shadow: (0px 0px 25px rgba(215, 65, 42, 0.5));
}

.luz {
  filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.luzhover:hover {
  filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.underline {
  color: $danger;
  font-size: 6rem;
  display: inline-block;
  position: relative;
}

.underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: green;
  transform-origin: bottom right;
  transition: transform 0.6s ease-out;
}

.underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.fondorojo {
  background-color: $danger;
  color: white;
  padding: 3rem;
}

.fondotransparente2 {
  background: #ee6f2f;
  border-radius: 5px;
  font-size: 1.4rem;
  color: rgba(87, 87, 87);
}

.fondotransparente3 {
  font-size: 1.4rem;
  padding: 0%;
  margin-left: 5% !important;
  margin-right: 0% !important;
  margin-top: 2rem;
  width: 100%;
  min-width: 320px;
  background: rgba(211, 211, 211);
  color: rgba(87, 87, 87);
}
.fondotransparente5 {
  font-size: 1.4rem;
  padding: 0%;
  margin-left: auto !important;
  margin-right: 0% !important;
  margin-top: 2rem;
  width: 83%;
  min-width: 320px;
  color: rgba(87, 87, 87);

}
.fondotransparente4 {
  color: rgba(87, 87, 87);
  padding: 0%;
  margin-left: auto !important;
  margin-right: 0% !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 95%;
  min-width: 320px;
  font-size: 1.4rem;
}

.fondotransparente {
  color: rgba(87, 87, 87);
  padding: 0%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 95%;
  min-width: 320px;
  font-size: 1.4rem;
}

/*# sourceMappingURL=styles.css.map */

.logob {
  max-width: 60vw;
  height: auto;
  display: flex;
  justify-content: center;
}

.centrar {

  display: block;
  margin-left: auto;
  margin-right: auto;
}

p {
  font-family: 'MiFuenteParrafo';
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  text-align: justify;
}

.cardt {
  max-width: 98%;
  width: 40rem;
}

.dropdown-item {
  font-weight: 600;
  color: rgba(59, 160, 168, 1);
}

.dropdown-item:hover {
  color: rgba(59, 160, 168, 1);
  background-color: rgba(215, 65, 42, 0.1);
  border-radius: 5px;
}

.sinvineta {
  list-style: none;
}

.form {
  max-width: 60%;
  padding: 1rem;
}


.container-fluid {
  margin-left: 0px;
  padding-left: 0px;
}

.letrachica {
  justify-content: center;
  font-size: 1.1rem;
  padding: 0%;
  margin: 0%;
  margin-left: auto;
  margin-right: 0%;
}

.online {
  color: rgb(0, 143, 252);
}

.inproloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 10000 !important;
}


.margenazul {
  border-left-style: solid !important;
  border-left-color: $primary !important;
  border-left: 4rem;
  padding-bottom: 3rem;
  margin-right: 20%;
  text-align: center;
}

.margenrojo {
  border-left-style: solid !important;
  border-left-color: $danger !important;
  border-left: 4rem;
  padding-bottom: 4rem;
  margin-right: 20%;
  text-align: center;

}



.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 40px;
  grid-auto-rows: 300px;
  margin: 5%;
  margin-top: 3rem;
  margin-bottom: 3rem;


.card {
    display: flex;


    .card-img {
      align-items: center;
      justify-items: center;
      border-radius: 0%;
      box-sizing: border-box !important;
      width: 100%;
      height: 300px;
      object-fit: cover;
      min-width: 200px;
      filter: brightness(60%);
    } 
  

  }
  
}

/* Estilos para escritorio */
@media (min-width: 769px) {
  .mobile-image {
    display: none;
  }
}
@media (max-width: 1400px) {

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: 400px;
  }
}



.cardaut {
  width: 40vw;
  min-width: 250px;
  max-width: 50vw;
  padding: 0% !important;
}

.botonbordeazul {
  display: inline-block;
  border-left-style: solid !important;
  border-left-color: $primary !important;
  border-left: 1rem;
  background-color: $danger;
  border-radius: 0%;
  box-shadow: (5px 7px 10px rgba(0, 0, 0, 0.5));
  font-family: 'MiFuentetitulo';
  text-align: center;

}

.containergrid {
  display: inline-block;
  border-left-style: solid !important;
  border-left-color: $primary !important;
  border-left: 1rem;
  border-radius: 10%;
  box-shadow: (5px 7px 10px rgba(0, 0, 0, 0.5));
  text-align: center;
  height: 650 px; 
  width: 100%;

}

.btn-titulo {
 display: block;
  text-align: center;
}

.contcaja {
  display: flex;
}

.titulocaja {
  size: 4rem;
  display: inline-block;
  color: white;
  font-weight: bold;
  padding: 1.3rem;
  font-family: 'MiFuentetitulo';
}

.cajaroja {
  display: inline-flex;
  background-color: $danger;
  width: 20%;
  text-align: center;
  text-justify: auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.cajazul {
  display: inline-flex;
  background-color: $primary;
  width: 20%;
  text-align: center;
  text-justify: auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.cajagris {
  display: inline-flex;
  background-color: rgb(87, 87, 87);
  width: 20%;
  text-align: center;
  text-justify: auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.cajagrisclaro {
  display: inline-flex;
  background-color: rgb(211, 211, 211);
  width: 20%;
  text-align: center;
  text-justify: auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.cuerpo {
  display: inline-block;
  width: 85%;
  padding: 2rem;
}

.casinos {
  width: 100vw !important;
}

.navbar-nav {
  text-align: center !important;
  align-items: center;
}

.letra_menu {
  font-family: 'MiFuentemenu';
}

.titulonoticias {
  font-family: 'MiFuentenoticiastitulo';
}

.titulop {
  font-family: 'MiFuentetitulo';
}

.contimg {
  width: 100%;


}
.foto{
  box-shadow: (5px 7px 10px rgba(0, 0, 0, 0.5));
}
.contimg img {

  width: 100%;
}

.contact {
  width: 100%;
  justify-content: center;
  padding: 4rem;
  background-color: $primary;
    color: white;
  .contactgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    grid-auto-rows: 550px;

    

  }

  .contactmap {
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 100px;

    .iframemap {
      width: 100%;
      height: 100%;
    }
  }
}
.adapt {
  height: 99vh;
  width: auto;
}

.bannerwp{
  background-color: rgb(0, 187, 45);
  display: inline-flex;
  width: 100%;
}
.textobannerwp{
  color: white;
  width: 50%;
  text-align: center;
  text-align: justify;
  text-justify: auto;
}
.textoqr{
  color: white;
  width: 20%;
}
.qrwp{
  width: 30%;
}
.qr{
  width: 80%;
}
@media (max-width: 768px) {

  .bannerwp{
    background-color: rgb(0, 187, 45);
    display: block;
    width: 100%;
  }

  .desktop-image {
    display: none;
  }

  .fondotransparente{
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .navbar-brand{
    width: 50%;
  }
  .ingresacupon{
    margin: opx;
  }


  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 400px;
    margin: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .contact{
  padding: 1rem;
  .contactgrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    grid-auto-rows: 550px;

  }}
  .fondotransparente4 {
    padding: 0%;
    margin-left: auto !important;
    margin-right: 0% !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  .fondotransparente {
    padding: 0%;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .cuerpo {
    display: inline-block;
    width: 100%;
    padding: 0rem;
    font-size: 1.5rem;
  }
  .titulocaja {
    size: 3rem;
    display: inline-block;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
    font-family: 'MiFuentetitulo';
  }

  .nav-item{
    padding: 1rem;
  }
  .adapt {
    height: auto;
    width: 100vw;
  }
}

.vhcomp{
  height: 75vh;
  display: flex;
  align-items: center;
}

.jus{
  text-align: left;
}
.carrousel-container {
  width: 90%; /* Ocupar el 90% del ancho de la pantalla */
  margin: 0 auto; /* Centrar el carrousel horizontalmente */
}

/* Estilos CSS para las imágenes del carrousel */
.carrousel-image {
  width: 100%; /* Ocupar el 100% del ancho del contenedor */
  height: auto; /* Ajustar la altura automáticamente según el ancho */
}


.button1 {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.thumbnail {
  flex-basis: calc(20% - 10px); /* 5 imágenes por línea */
  margin: 5px;
}
.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail img {
  width: 100%;
  height: auto;
}
.btn{
  border: 1px solid;
}
.max-height-300 {
  max-height: 500px; /* Ajusta este valor según tus necesidades */
}

.Card {
  margin-bottom: 20px; /* Agrega espacio vertical entre tarjetas */
  /* Otros estilos de la tarjeta */
}
.logoconbotones{
position: relative;
display: inline-block;
min-width: 70%;
max-width: 100%;
}
.logofooter{
width: 100%;
}

.boton-youtube{
  position: absolute;
top: 14%;
right: 2%;
background: none;
border-style: none;
width: 7%;
padding: 0%;
margin: 0%;
}

.boton-instagram{
  position: absolute;
top: 44%;
right: 2%;
background: none;
border-style: none;
width: 7%;
padding: 0%;
margin: 0%;
}
.boton-face{
  position: absolute;
top: 74%;
right: 2%;
background: none;
border-style: none;
width: 7%;
padding: 0%;
margin: 0%;
}
.youbtnimg{
  width: 100%;
}
.instbtnimg{
  width: 100%;
}
.facebtnimg{
  width: 100%;
}
body{    
  width:100%;
  overflow-x: hidden;
}

/* Estilos CSS */
.news-container {
  display: flex;
  max-height: 80vh;
  overflow: hidden;
}

.main-news {
  flex: 2;
  max-height: 100%;
}

.sidebar-news {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
}

.sidebar-news-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
}

/* Estilos CSS para las tarjetas (ajustar según sea necesario) */
.card {
  height: 100%; /* Ajustar el alto según tus necesidades */
  border: 1px solid #ddd; /* Bordes opcionales */
  border-radius: 4px; /* Bordes redondeados opcionales */
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-img {
  max-width: 100%;
  height: auto;
}

.card-title {
  margin-top: 10px; /* Espacio superior opcional */
}

/* Cards.css */

/* Estilos para móviles */
@media (max-width: 768px) {
  .mobile-title {
    font-size: 2em !important;
  }

  .mobile-subtitle {
    font-size: 0.95em !important;
  }
}

/* Estilos para escritorio */
@media (min-width: 769px) {
  .desktop-title {
    font-size: 40px !important;
  }

  .desktop-subtitle {
    font-size: 1.5em !important;
  }
}

//gggg
/* styles.css */
/* styles.css */
.form-floating-custom {
  position: relative;
}

.form-floating-custom label {
  position: absolute !important;
  pointer-events: none;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease-out;
  font-size: 14px !important;
  color: #495057 !important;
}

.form-floating-custom input {
  padding: 10px; /* Ajusta el relleno según tus preferencias */
}

.form-floating-custom input:focus + label,
.form-floating-custom input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  color: #6c757d;
}

.photo-container {
  position: relative;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
}

.layout {
  width: 1366px;
  height: 768px;

  display: grid;
  grid:
    "sidebar body" 1fr
    / 30% 1fr;
  gap: 8px;
}

.sidebar { grid-area: sidebar; }
.body { grid-area: body; }


//Botones

button {
  font-size: 17px;
  padding: 0.5em 2em;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
  background: #87aff3;
  color: white;
  border-radius: 4px;
}

button:hover {
  background: rgb(170, 201, 219);
}

button:active {
  transform: translate(0em, 0.2em);
}

.eliminar-alumno {
  background: #ff4d4d;
  color: white;
}

.eliminar-alumno:hover {
  background: #ff3333;
}

.eliminar-alumno:active {
  transform: translate(0em, 0.2em);
}

.agregar-alumno {
  background: #4caf50;
  color: white;
}

.agregar-alumno:hover {
  background: #45a049;
}

.agregar-alumno:active {
  transform: translate(0em, 0.2em);
}


  
//Botones Fin


.btn-class-name {
  --primary: 215, 65, 42;
  --secondary: 150, 50, 60;
  width: 60px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  outline: 8px solid rgb(var(--primary), .5);
  border-radius: 100%;
  position: relative;
  transition: .3s;
}

.btn-class-name .back {
  background: rgb(var(--secondary));
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.btn-class-name .front {
  background: linear-gradient(0deg, rgba(var(--primary), .6) 20%, rgba(var(--primary)) 50%);
  box-shadow: 0 .5em 1em -0.2em rgba(var(--secondary), .5);
  border-radius: 100%;
  position: absolute;
  border: 1px solid rgb(var(--secondary));
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: inherit;
  transform: translateY(-15%);
  transition: .25s;
  color: rgb(239, 238, 238);
}

.btn-class-name:active .front {
  transform: translateY(0%);
  box-shadow: 0 0;
}

#load {
  position: absolute;
  width: 600px;
  height: 36px;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#load div {
  position: absolute;
  width: 20px;
  height: 36px;
  opacity: 0;
  font-family: Helvetica, Arial, sans-serif;
  animation: move 2s linear infinite;
  -o-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -webkit-animation: move 2s linear infinite;
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  color: #35C4F0;
}

#load div:nth-child(2) {
  animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

#load div:nth-child(3) {
  animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

#load div:nth-child(4) {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

#load div:nth-child(5) {
  animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

#load div:nth-child(6) {
  animation-delay: 1s;
  -o-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

#load div:nth-child(7) {
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#load div:nth-child(8) {
  animation-delay: 1.4s;
  -o-animation-delay: 1.4s;
  -moz-animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
}


@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

//RADIO inicio

.radio-buttons-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  color: #140e0e;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
}

.radio-button__custom {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #555;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
}

.radio-button__input:checked + .radio-button__label .radio-button__custom {
  transform: translateY(-50%) scale(0.9);
  border: 5px solid #4c8bf5;
  color: #4c8bf5;
}

.radio-button__input:checked + .radio-button__label {
  color: #2ad056;
}

.radio-button__label:hover .radio-button__custom {
  transform: translateY(-50%) scale(1.2);
  border-color: #0a43a4;
  box-shadow: 0 0 10px #4c8bf580;
}



//Radio Fin

a {
  color: hwb(217 25% 5%); /* Establecer el color del texto del enlace en negro */
  text-decoration: none; /* Quitar el subrayado predeterminado del enlace */
}

// concurso
.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.document-details {
  flex: 1;
}

.document-actions {
  display: flex;
  justify-content: flex-end;

}

.document-actions button {
  margin-left: 10px;
}

/* Estilos en línea para el botón de cierre */
.p-dialog-header-close {
  color: #333; /* Color del texto */
  font-size: 14px; /* Tamaño de la fuente */
  font-weight: bold; /* Fuente en negrita */
}


.titulo1 {
  border-left: 1rem;
  //background-color: $danger;
  //border-radius: 0%;
  //box-shadow: (5px 7px 10px rgba(0, 0, 0, 0.5));
  font-family: 'MiFuentetitulo';
  text-align: center;
  font-size:clamp(1rem, 2vw, 2.5rem);


}

.titulo2 {
  border-left: 1rem;
  font-family: 'MiFuentenoticiastitulo';
  text-align: center;
  font-size: clamp(1.5rem, 2.5vw, 3rem); /* El tamaño de la fuente variará entre 1.5rem y 3rem según el tamaño de la ventana */
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombreado gris */

}


.titulo3 {
  border-left: 1rem;
  //background-color: $danger;
  //border-radius: 0%;
  //box-shadow: (5px 7px 10px rgba(0, 0, 0, 0.5));
  font-family: 'MiFuentenoticiastitulo';
  text-align: center;
  font-size:clamp(1rem, 2vw, 2.5rem);
  color: $danger;

}

.mifuente {
  font-family: 'MiFuenteparrafo'};

.titconcurso {
  border-left: 1rem;
  /* background-color: $danger; */
  /* border-radius: 0%; */
  /* box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.5); */
  font-family: 'MiFuentenoticiastitulo';
  text-align: center;
  font-size: clamp(1.8rem, 2.7vw, 3.2rem);
  color: white;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7); /* Sombreado gris */
}


//flecha de carrusel
.custom-flecha {
  width: 30px;
  height: 30px;
  z-index: 1;
}

.custom-next-flecha::before,
.custom-prev-flecha::before {
  font-size: 30px;
  color: #d7412a; /* Cambia esto al color que desees */
}

.fondomenufooter {
  background: #e9e5e4;
  border-radius: 5px;
  font-size: 1.4rem;
  color: rgba(87, 87, 87);
}

.fondo {
  background: linear-gradient(rgba(5, 5, 5, 0.30), rgba(5, 5, 5, 0.30) ), url(camfondo.jpeg) no-repeat center center ;
  background-size: cover;
}

.sobrefondo{
  background:rgba(5, 5, 5, 0.20) ;
  background: initial;
}

.mobile-image-container {
  height: 50%; /* Ajusta según sea necesario */
}
.mobile-cards-container {
  height: 50%; /* Ajusta según sea necesario */

}

.mobile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.mobile-title, .mobile-subtitle {
  text-align: center;
}

.desktop-title, .desktop-subtitle {
  text-align: left;
}